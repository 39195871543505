let nonce = Math.floor(performance.now());

export async function fetchScript(url: string, mayCache = true, retries = 3) {
  const script = document.createElement("script");
  script.src = url;

  if (!mayCache) {
    let c = "?";
    if (script.src.includes(c)) {
      c = "&";
    }

    script.src += `${c}_=${(nonce++).toString()}`;
  }

  return new Promise<void>((resolve, reject) => {
    script.addEventListener("load", () => {
      script.remove();
      resolve();
    });

    script.addEventListener("error", (event) => {
      script.remove();

      if (retries) {
        resolve(fetchScript(url, mayCache, retries - 1));
        return;
      }

      console.error(`Failed to fetch script: ${url}`);
      console.error(event);
      reject(event.error as Error);
    });

    document.head.append(script);
  });
}
